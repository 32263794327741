<template>
  <div class="header">
    <img src="/images/icons/burger.png" alt="" class="header__burger" @click="toggleMenu"/>
    <div class="header__user pointer" tabindex="0" @focusin="toggleOptions" @focusout="toggleOptions">
      <img :src="me.avatar || '/images/avatar.jpg'" alt=""/>
      <span>{{me.first_name}} {{me.last_name}}</span>
      <div class="header__options" v-if="optionsOpened">
        <div class="header__option" @click="logout" >
          <i class="fas fa-sign-out-alt"/> {{$t('global.sign_out')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Auth } from '@/api/api'
export default {
  name: 'Header',
  props: [
    'isMenuOpen'
  ],
  data () {
    return {
      optionsOpened: false
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    })
  },
  methods: {
    toggleOptions () {
      this.optionsOpened = !this.optionsOpened
    },
    toggleMenu () {
      this.$emit('toggleMenu', {})
    },
    async logout () {
      try {
        await Auth.logout()
      } catch (e) {
      } finally {
        this.$store.dispatch('auth/logout')
        window.setTimeout(() => {
          this.$router.push({ name: 'Landing' })
        }, 0)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
