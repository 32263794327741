<template>
  <div class="side-menu">
    <img src="/images/logo.png" class="side-menu__logo" alt=""/>
    <h3 v-if="isVendor">{{$t('menu.title')}}</h3>
    <h3 v-if="isAdmin || isOperator">{{$t('menu.menu')}}</h3>
    <router-link v-if="isVendor && isVerified" to="/dashboard/orders" class="side-menu__link" :class="{'router-link-active': isActive('dashboard/orders')}">
      <img src="/images/icons/order.svg" alt=""/>
      <span>{{$t('menu.orders')}}</span>
    </router-link>
    <router-link v-if="isVendor && isVerified" to="/dashboard/products" class="side-menu__link" :class="{'router-link-active': isActive('dashboard/products')}">
      <img src="/images/icons/product2.svg" alt=""/>
      <span>{{$t('menu.products')}}</span>
    </router-link>
    <router-link v-if="isVendor && isVerified" to="/dashboard/profile/me" class="side-menu__link" :class="{'router-link-active': isActive('dashboard/profile')}">
      <img src="/images/icons/profile.svg" alt=""/>
      <span>{{$t('menu.profile')}}</span>
    </router-link>
    <router-link v-if="isVendor && !isVerified" to="/dashboard/profile/me/edit" class="side-menu__link" :class="{'router-link-active': isActive('dashboard/profile')}">
      <img src="/images/icons/profile.svg" alt=""/>
      <span>{{$t('menu.onboarding')}}</span>
    </router-link>

    <router-link v-if="isAdmin || isOperator" to="/dashboard/admin/products" class="side-menu__link" :class="{'router-link-active': isActive('admin/products')}">
      <img src="/images/icons/product.svg" alt=""/>
      <span>{{$t('menu.products')}}</span>
    </router-link>

    <router-link v-if="isAdmin || isOperator" to="/dashboard/admin/merchants" class="side-menu__link" :class="{'router-link-active': isActive('admin/merchants')}">
      <img src="/images/icons/product2.svg" alt=""/>
      <span>{{$t('menu.merchants')}}</span>
    </router-link>

    <router-link v-if="isAdmin || isOperator" to="/dashboard/admin/orders" class="side-menu__link" :class="{'router-link-active': isActive('admin/orders')}">
      <img src="/images/icons/order.svg" alt=""/>
      <span>{{$t('menu.orders')}}</span>
    </router-link>

    <div class="side-menu__sub" v-if="isAdmin">
      <router-link to="/dashboard/admin/settings/wide" class="side-menu__link" :class="{'router-link-active': isActive('admin/settings')}">
        <img src="/images/icons/settings.svg" alt=""/>
        <span>{{$t('menu.settings')}}</span>
      </router-link>
      <div class="side-menu__sub-line"/>
      <router-link to="/dashboard/admin/settings/wide" class="side-menu__a" :class="{'router-link-active': isActive('admin/settings/wide')}">
        <strong/>
        <span>{{$t('menu.wide')}}</span>
      </router-link>
      <router-link to="/dashboard/admin/settings/banner" class="side-menu__a" :class="{'router-link-active': isActive('admin/settings/banner')}">
        <strong/>
        <span>{{$t('menu.banner')}}</span>
      </router-link>
      <router-link to="/dashboard/admin/settings/push" class="side-menu__a" :class="{'router-link-active': isActive('admin/settings/push')}">
        <strong/>
        <span>{{$t('menu.push')}}</span>
      </router-link>
    </div>

    <div class="side-menu__sub" v-if="isOperator">
      <router-link to="/dashboard/admin/settings/banner" class="side-menu__link" :class="{'router-link-active': isActive('admin/settings')}">
        <img src="/images/icons/settings.svg" alt=""/>
        <span>{{$t('menu.settings')}}</span>
      </router-link>
    </div>
    <div class="side-menu__lang">
      <span :class="{selected: lang === 'zh-hk'}" @click="changeLang('zh-hk')">繁體中文</span> / <span :class="{selected: lang === 'en'}" @click="changeLang('en')">English</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { User } from '@/api/api'

export default {
  name: 'SideBar',
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me',
      isAdmin: 'auth/isAdmin',
      isVerified: 'auth/isVerified',
      isOperator: 'auth/isOperator',
      isVendor: 'auth/isVendor'
    })
  },
  data () {
    return {
      lang: 'en'
    }
  },
  mounted () {
    this.lang = this.me.language || window.navigator.userLanguage || window.navigator.language

    this.$i18n.locale = this.lang
    window.lifeslocale = this.lang
    if (this.me.language !== this.lang) {
      this.saveLanguage()
    }
  },
  methods: {
    isActive (path) {
      return this.$route.path.indexOf(path) !== -1
    },
    async saveLanguage () {
      try {
        await User.updateUser(this.me.id, {
          language: this.lang
        })
      } catch (err) {
        console.error('Failed to save selected language')
      }
    },
    changeLang (lang) {
      if (lang !== this.lang) {
        this.lang = lang
        window.lifeslocale = this.lang
        this.$i18n.locale = this.lang
        this.saveLanguage()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
