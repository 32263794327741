<template>
  <div class="page" :class="{ 'menu-open': isMenuOpen }">
    <div class="page__side">
      <side-bar/>
    </div>
    <div class="page__right">
      <div class="page__header">
        <top-header :isMenuOpen="isMenuOpen" @toggleMenu="toggleMenu"/>
      </div>
      <div class="page__main">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/header'
import SideBar from '@/components/sideBar/sideBar'

export default {
  name: 'Dashboard',
  components: {
    'top-header': Header,
    'side-bar': SideBar
  },
  data () {
    return {
      isMenuOpen: true
    }
  },
  methods: {
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style.scss";
</style>
